// src/slices/salamanderSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {
    createSalamanderProduct,
    deleteSalamanderProduct,
    fetchSalamanderProducts,
    reorderSalamanderProducts,
    updateSalamanderProduct,
    fetchSalamanderProduct
} from "../../queries/salamander-lviv";

const salamanderSlice = createSlice({
    name: 'salamander-lviv',
    initialState: {
        products: [],
        product: null,
        status: 'idle',
        statusProduct: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSalamanderProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSalamanderProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload;
            })
            .addCase(fetchSalamanderProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchSalamanderProduct.pending, (state) => {
                state.statusProduct = 'loading';
            })
            .addCase(fetchSalamanderProduct.fulfilled, (state, action) => {
                state.statusProduct = 'succeeded';
                state.product = action.payload;
            })
            .addCase(fetchSalamanderProduct.rejected, (state, action) => {
                state.statusProduct = 'failed';
                state.error = action.error.message;
            })
            .addCase(createSalamanderProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createSalamanderProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products.push(action.payload.product);
            })
            .addCase(createSalamanderProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateSalamanderProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSalamanderProduct.fulfilled, (state, action) => {
                const updatedProduct = action.payload.product;
                state.products = state.products.map(p =>
                    p._id === updatedProduct._id ? updatedProduct : p
                );
                state.status = 'succeeded';
            })
            .addCase(updateSalamanderProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteSalamanderProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteSalamanderProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = state.products.filter(p => p._id !== action.payload._id);
            })
            .addCase(deleteSalamanderProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(reorderSalamanderProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(reorderSalamanderProducts.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(reorderSalamanderProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default salamanderSlice.reducer;
