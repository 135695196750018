import {configureStore} from '@reduxjs/toolkit';
import userReducer from '@services/reducers/user';
import { createBrowserHistory } from 'history';
import windowsillSlice from  '@services/reducers/windowsill';
import mosquitoNetsSlice from  '@services/reducers/mosquitoNets';
import drainagesSlice from  '@services/reducers/drainages';
import accessoriesSlice from  '@services/reducers/accessories';
import salamanderSlice from  '../services/reducers/salamander-lviv';
import basketSlice from '../services/reducers/basketSlice';
import {saveToLocalStorage} from "@services/middleware";


export const history = createBrowserHistory();



const store = configureStore({
    reducer: {
        user: userReducer,
        windowsill: windowsillSlice,
        mosquitoNets: mosquitoNetsSlice,
        accessories: accessoriesSlice,
        drainages: drainagesSlice,
        basket: basketSlice,
        'salamander-lviv': salamanderSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveToLocalStorage)
});

export default store;


