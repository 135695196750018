import { Button, Grid, Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createSalamanderProduct } from "@services/queries/salamander-lviv";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
};

export const AddSalamanderProductModal = ({ open, handleClose, position }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const category = params.page;

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [image, setImage] = useState(null);

    const [isNameValid, setIsNameValid] = useState(true);
    const [isDescriptionValid, setIsDescriptionValid] = useState(true);
    const [isPriceValid, setIsPriceValid] = useState(true);
    const [isImageValid, setIsImageValid] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();

        let valid = true;

        if (name.trim() === "") {
            setIsNameValid(false);
            valid = false;
        }

        if (description.trim() === "") {
            setIsDescriptionValid(false);
            valid = false;
        }

        if (price === "" || isNaN(price)) {
            setIsPriceValid(false);
            valid = false;
        }

        if (!image) {
            setIsImageValid(false);
            valid = false;
        }

        if (!valid) return;

        const formData = new FormData();
        formData.append("category", category);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("position", position);
        formData.append("image", image);

        dispatch(createSalamanderProduct(formData));

        handleClose();
        resetForm();
    };

    const resetForm = () => {
        setName("");
        setDescription("");
        setPrice("");
        setImage(null);
        setIsNameValid(true);
        setIsDescriptionValid(true);
        setIsPriceValid(true);
        setIsImageValid(true);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                setIsImageValid(false);
                return;
            }
            setImage(file);
            setIsImageValid(true);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box component="form" onSubmit={handleSubmit} sx={style}>
                <Typography variant="h6">Додати товар (Salamander)</Typography>

                <TextField
                    fullWidth
                    label="Імʼя"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!isNameValid}
                    helperText={!isNameValid && "Введіть імʼя"}
                    sx={{ mt: 2 }}
                    size="small"
                />

                <TextField
                    fullWidth
                    label="Опис"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    error={!isDescriptionValid}
                    helperText={!isDescriptionValid && "Введіть опис"}
                    sx={{ mt: 2 }}
                    size="small"
                />

                <TextField
                    fullWidth
                    label="Ціна за метр квадратний"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    error={!isPriceValid}
                    helperText={!isPriceValid && "Введіть ціну (число)"}
                    sx={{ mt: 2 }}
                    size="small"
                />

                <Grid item display='flex' alignItems='center' sx={{ mt: 2 }}>
                    <Button variant="contained" component="label" color={isImageValid ? "primary" : "error"} size="small">
                        Додати фото
                        <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                    </Button>
                    <Typography variant="subtitle2" sx={{ ml: 2 }} color={!isImageValid ? "error" : "inherit"}>
                        {image?.name || "Фото не вибрано"}
                    </Typography>
                </Grid>

                <Grid item display='flex' justifyContent='center' sx={{ mt: 3 }}>
                    <Button type="submit" variant="contained" color="success">
                        Додати товар <CreateNewFolderIcon sx={{ ml: 1 }} />
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};