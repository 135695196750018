import { Button, Grid, Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import { updateSalamanderProduct } from "@services/queries/salamander-lviv";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 20px;
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
};

export const EditSalamanderProductModal = ({ open, handleClose, product }) => {
    const dispatch = useDispatch();
    const [image, setImage] = useState('');
    const [name, setName] = useState(product.name || '');
    const [description, setDescription] = useState(product.description || '');
    const [price, setPrice] = useState(product.price || 0);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) setImage(file);
    };

    const handleSave = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('price', price);
        if (image) formData.append('image', image);

        dispatch(updateSalamanderProduct({ id: product._id, formData }));
        handleClose();
    };


    useEffect(() => {
        if (open) {
            setName(product.name || '');
            setDescription(product.description || '');
            setPrice(product.price || 0);
            setImage('');
        }
    }, [open, product]);


    return (
        <Modal open={open} onClose={handleClose}>
            <Box component="form" onSubmit={handleSave} sx={style}>
                <StyledTypography variant="h6">Редагувати товар</StyledTypography>

                <TextField
                    fullWidth
                    margin="normal"
                    label="Назва"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    label="Опис"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    type="number"
                    label="Ціна"
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                />

                <Grid item mt={2} display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant="contained" component="label" size="small">
                        Змінити фото
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={handleImageChange}
                        />
                    </Button>
                    <Typography variant="caption">
                        {image?.name || product.image?.key || 'Фото не вибрано'}
                    </Typography>
                </Grid>

                <Grid item mt={3} display='flex' justifyContent='center'>
                    <Button type="submit" variant="contained" color="success">
                        Зберегти зміни
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

export default EditSalamanderProductModal;
