import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useMemo } from 'react';


const ProtectedRoute = ({ element: Element, ...rest }) => {
    const { isLoggedIn, role } = useSelector(state => state.user);

    const isAuthorized = useMemo(() => isLoggedIn && role === 'admin', [isLoggedIn, role]);

    return isAuthorized ? (
        <Element {...rest} />
    ) : (
        <Navigate to="/login" />
    );
};


export default ProtectedRoute;


