import CallToActionIcon from "@mui/icons-material/CallToAction";
import WavesIcon from "@mui/icons-material/Waves";
import TagIcon from "@mui/icons-material/Tag";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import {fetchWindowsillFilters, fetchWindowsillProducts} from "@services/queries/windowsill";
import {fetchMosquitoNetsFilters, fetchMosquitoNetsProducts} from "@services/queries/mosquitoNets";
import {fetchDrainagesFilters, fetchDrainagesProducts} from "@services/queries/drainages";
import {fetchAccessoriesFilters, fetchAccessoriesProducts} from "@services/queries/accessories";
import WindowIcon from "@mui/icons-material/Window";
import React from "react";

export const drawerWidth = 240;
export const headerHeight = '120px';
export const footerHeight = '180px';

export const breakpoints = {
    mobile: '600px',
    tablet: '1000px',
}

export const navList = [
    {icon: <TagIcon />, title: 'Москітні сітки', page: 'mosquitoNets'},
    {icon: <CallToActionIcon />, title: 'Підвіконня', page: 'windowsill'},
    {icon: <WavesIcon />, title: 'Водовідливи', page: 'drainages'},
    {icon: <FormatColorTextIcon />, title: 'Аксесуари', page: 'accessories'},
]

export const salamanderPage = {
    page: 'salamander-lviv',
    icon: <WindowIcon />,
    title: "Salamander-Lviv"
};

export const categoryFetchActions = {
    windowsill: {
        fetchProducts: fetchWindowsillProducts,
        fetchFilters: fetchWindowsillFilters,
    },
    mosquitoNets: {
        fetchProducts: fetchMosquitoNetsProducts,
        fetchFilters: fetchMosquitoNetsFilters,
    },
    drainages: {
        fetchProducts: fetchDrainagesProducts,
        fetchFilters: fetchDrainagesFilters,
    },
    accessories: {
        fetchProducts: fetchAccessoriesProducts,
        fetchFilters: fetchAccessoriesFilters,
    },
    // Add other categories if needed
};