// Footer.jsx
import React from 'react';
import {
    DividerStyled,
    StyledFooter,
    WrapperCopyrightStyled,
    WrapperFooterContent,
    WrapperFooterItem,
    StyledTypographyFooterLink, CustomLink, RightSideStyled,
} from "./styledComponents";
import { StyledTypographyFooter } from "./styledComponents";
import {Link} from '@mui/material';


export const Footer = ({ theme, backToHome }) => (
    <StyledFooter theme={theme}>
        <WrapperFooterContent>
            {/* Ліва частина футера (пусто або інший контент) */}
            <div style={{ flex: 1 }}></div>

            {/* Права частина футера з двома секціями */}
            <RightSideStyled>


                {/* Секція "Документи та правила" */}
                <WrapperFooterItem theme={theme}>
                    <StyledTypographyFooter theme={theme} bold>
                        Документи та правила
                    </StyledTypographyFooter>
                    <StyledTypographyFooterLink to="/terms-and-conditions" theme={theme}>
                        Правила та умови
                    </StyledTypographyFooterLink>
                    <StyledTypographyFooterLink to="/refund-policy" theme={theme}>
                        Політика повернення коштів
                    </StyledTypographyFooterLink>
                </WrapperFooterItem>

                <DividerStyled color={theme.palette.primary.contrastText} orientation="vertical" flexItem />
                {/* Секція "Контактна інформація" */}
                <WrapperFooterItem theme={theme}>
                    <StyledTypographyFooter theme={theme} bold>
                        Контактна інформація
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>Адреса:</strong> Львів, вул. Наукова 43 б
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>Контакти: </strong>
                        <CustomLink theme={theme} href="tel:+380678602100">
                            +380678602100
                        </CustomLink>
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>Емейл: </strong>
                        <CustomLink theme={theme} href="mailto:nobilux.vikna@ukr.net">
                            nobilux.vikna@ukr.net
                        </CustomLink>
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>Графік роботи:</strong> Пн-Пт: 9:00-18:00
                    </StyledTypographyFooter>
                </WrapperFooterItem>

                {/* Нова секція "Інформація про ФОП" */}
                <WrapperFooterItem theme={theme}>
                    <StyledTypographyFooter theme={theme} bold>
                        Інформація про ФОП
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>ФО-П:</strong> Кузьменко Уляна Романівна
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>ІПН (Код одержувача):</strong> 3231705005
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>Банк:</strong> АТ КБ "ПриватБанк"
                    </StyledTypographyFooter>
                    <StyledTypographyFooter theme={theme}>
                        <strong>IBAN:</strong> UA483052990000026007001027981
                    </StyledTypographyFooter>
                </WrapperFooterItem>
            </RightSideStyled>
        </WrapperFooterContent>

        {/* Підвал з авторським правом */}
        <WrapperCopyrightStyled>
            <StyledTypographyFooter style={{ fontStyle: "italic" }} theme={theme}>
                © {new Date().getFullYear()}. Усі права захищені.
            </StyledTypographyFooter>
        </WrapperCopyrightStyled>
    </StyledFooter>
);