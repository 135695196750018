import React, { useEffect } from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const PaymentSuccess = () => {

    useEffect(() => {
        // Очищуємо basketItems після успішної оплати
        localStorage.setItem('basketItems', JSON.stringify([]));
    }, []);

    return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Avatar sx={{ bgcolor: 'success.main', width: 70, height: 70, mx: 'auto', mb: 2 }}>
                <CheckIcon sx={{ color: '#fff', fontSize: 40 }} />
            </Avatar>
            <Typography variant="h4" color="success.main">
                Оплата пройшла успішно!
            </Typography>
            <Typography variant="body1" sx={{ my: 2 }}>
                Дякуємо за замовлення. Найближчим часом ми зв'яжемося з вами для уточнення деталей.
            </Typography>
            <Button variant="contained" href="/">
                Повернутися на головну
            </Button>
        </Box>
    );
};

