import { useState, useEffect } from 'react';

const calculateAdditionalPrice = (listAdditionalPrice, counts) => {
    return listAdditionalPrice.reduce((totalPrice, item, index) => {
        return totalPrice + (item.price * counts[index]);
    }, 0);
}

export const calculateCountsPrice = (currentPrice, counts = 1) => currentPrice * counts;

export const calculateTotalPrice = (currentPrice, selectedLength, additionalPriceChecked, selectedCounts) => {
    // Обчислити вартість за вибрану довжину
    const priceForSelectedLength = Math.round(currentPrice * (selectedLength / 1000));

    // Обчислити додаткову вартість
    const additionalPrice = calculateAdditionalPrice(additionalPriceChecked, selectedCounts);

    // Загальна вартість - додаткова вартість додається до вартості за вибрану довжину
    return priceForSelectedLength + additionalPrice;
}

export const calculateTotalPriceArea = (currentPrice, additionalPriceChecked, selectedCounts) => {
    // Обчислити додаткову вартість
    const additionalPrice = calculateAdditionalPrice(additionalPriceChecked, selectedCounts);

    // Загальна вартість - додаткова вартість додається до вартості за вибрану довжину
    const totalPrice = currentPrice + additionalPrice;

    // Округлити результат до цілого числа
    return Math.round(totalPrice);
}


export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        handleResize(); // Initial size

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export const calculateCost = (lengthMm, widthMm, pricePerSquareMeter) => {
    const lengthMeters = lengthMm / 1000;
    const widthMeters = widthMm / 1000;

    const area = lengthMeters * widthMeters;

    return area * pricePerSquareMeter;

}

