import React from 'react';
import {Typography, Container, Box, Link, Button} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

export const TermsAndConditions = () => {
    const navigate = useNavigate();
    const backToHome = () => navigate('/')
    return (
        <Container maxWidth="md">
            <Button startIcon={<ArrowBackIcon/>} onClick={backToHome}>Повернутись на головну</Button>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Правила та умови використання сайту
                </Typography>

                {/* Вступ та загальні положення */}
                <Typography variant="body1" paragraph>
                    Ласкаво просимо на сайт moskitkalviv.com (далі — «сайт»). Використовуючи наш сайт, ви погоджуєтеся з
                    цими правилами та умовами. Якщо ви не згодні з цими умовами, будь ласка, не використовуйте наш сайт.
                </Typography>

                {/* Політика конфіденційності */}
                <Typography variant="h6" component="h2" gutterBottom>
                    Політика конфіденційності
                </Typography>
                <Typography variant="body1" paragraph>
                    Ця Політика конфіденційності описує, як ФО-П Кузьменко Уляна Романівна (далі — «виконавець») може
                    збирати й використовувати інформацію про клієнтів у зв’язку з їх доступом і використанням сайту
                    moskitkalviv.com та його піддоменів (далі — «сайт»).
                </Typography>

                {/* Збір та використання персональних даних */}
                <Typography variant="h6" component="h2" gutterBottom>
                    Збір та використання персональних даних
                </Typography>
                <Typography variant="body1" paragraph>
                    Ми збираємо такі персональні дані:
                </Typography>
                <Typography variant="body1" component="ul" gutterBottom>
                    <li>Email клієнта для зворотного зв’язку.</li>
                    <li>Ім’я та номер телефону клієнта для підтвердження замовлення.</li>
                    <li>Інформація для доставки замовлення (якщо клієнт не вибрав самовивіз).</li>
                </Typography>
                <Typography variant="body1" paragraph>
                    Зібрані дані використовуються виключно для обробки та доставки замовлень, а також для зв'язку з
                    клієнтом у разі необхідності.
                </Typography>

                {/* Процес замовлення товару */}
                <Typography variant="h6" component="h2" gutterBottom>
                    Процес замовлення товару
                </Typography>
                <Typography variant="body1" paragraph>
                    Для замовлення товару на нашому сайті виконайте наступні кроки:
                </Typography>
                <Typography variant="body1" component="ol" gutterBottom>
                    <li>Обирайте потрібні товари, додаючи їх у кошик.</li>
                    <li>Перейдіть на сторінку кошика та заповніть персональні дані для оплати та доставки товару.</li>
                    <li>Після підтвердження даних вас буде перенаправлено на платіжну систему WayForPay для
                        безпосередньої оплати замовлення.
                    </li>
                    <li>Після успішної оплати ви та продавець отримаєте підтвердження замовлення електронною поштою.
                    </li>
                    <li>Наш менеджер зв’яжеться з вами найближчим часом для підтвердження замовлення та уточнення
                        деталей.
                    </li>
                </Typography>

                {/* Способи оплати */}
                <Typography variant="h6" component="h2" gutterBottom>
                    Способи оплати
                </Typography>
                <Typography variant="body1" paragraph>
                    Ми пропонуємо такі способи оплати для зручності наших клієнтів:
                </Typography>
                <Typography variant="body1" component="ul" gutterBottom>
                    <li><strong>WayForPay:</strong> Ви можете здійснити онлайн-оплату через платіжну систему WayForPay, використовуючи банківські картки Visa або MasterCard.</li>
                </Typography>

                {/* Умови доставки */}
                <Typography variant="h6" component="h2" gutterBottom>
                    Умови доставки
                </Typography>
                <Typography variant="body1" paragraph>
                    Ми пропонуємо наступні варіанти доставки:
                </Typography>
                <Typography variant="body1" component="ul" gutterBottom>
                    <li><strong>Доставка по Львову:</strong> Доставка здійснюється по місту Львів протягом 1-3 робочих днів після підтвердження замовлення.</li>
                    <li><strong>Самовивіз:</strong> Ви можете самостійно забрати своє замовлення з нашого офісу за адресою: м. Львів, вул. Наукова 43 б. </li>
                    <li><strong>Графік роботи:</strong>  Офіс працює з 9:00 - 18:00 з пн-пт.</li>
                </Typography>

                {/* ФОП Інформація */}
                <Typography variant="h6" component="h2" gutterBottom>
                    Інформація про ФОП
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>ФО-П:</strong> Кузьменко Уляна Романівна<br/>
                    <strong>ІПН (Код одержувача):</strong> 3231705005<br/>
                    <strong>Банк:</strong> АТ КБ "ПриватБанк"<br/>
                    <strong>IBAN:</strong> UA483052990000026007001027981
                </Typography>

                {/* Контактна інформація */}
                <Typography variant="h6" component="h2" gutterBottom>
                    Контактна інформація
                </Typography>
                <Typography variant="body1" paragraph>
                    Якщо у вас виникли питання або пропозиції, будь ласка, зв'яжіться з нами:
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Телефон:</strong>{' '}
                    <Link href="tel:+380678602100">+380678602100</Link><br/>
                    <strong>Email:</strong>{' '}
                    <Link href="mailto:nobilux.vikna@ukr.net">nobilux.vikna@ukr.net</Link><br/>
                    <strong>Адреса:</strong> м. Львів, вул. Наукова 43 б
                </Typography>

                {/* Заключні положення */}
                <Typography variant="body1" paragraph>
                    Ми залишаємо за собою право вносити зміни до цих правил та умов у будь-який час. Зміни набувають
                    чинності з моменту їх публікації на сайті.
                </Typography>
            </Box>
        </Container>
    )
};