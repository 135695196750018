// 🧩 CollapsibleTable адаптований для Salamander
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { TableHeader } from "./tableHeader";
import { useDispatch } from "react-redux";
import { getItemStyle } from "./utils";
import { reorderSalamanderProducts } from "@services/queries/salamander-lviv";
import { Row } from "./row";

export const SalamanderCollapsibleTable = ({ products }) => {
    const dispatch = useDispatch();
    const [items, setItems] = useState(products);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const updatedItems = Array.from(items);
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        setItems(updatedItems);

        const productOrder = updatedItems.map((product) => product._id);
        dispatch(reorderSalamanderProducts(productOrder));
    };

    useEffect(() => {
        setItems(products);
    }, [products]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHeader />
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <TableBody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {items.map((product, index) => (
                                    <Draggable
                                        key={product._id}
                                        draggableId={`salamander-${product._id}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <Row
                                                provided={provided}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                product={product}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </DragDropContext>
            </Table>
        </TableContainer>
    );
};
