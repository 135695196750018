import { Button, FormControlLabel, FormGroup, Grid} from "@mui/material";
import React, { useState } from "react";

import {
    ProductBlockContainer,
    StyledButtonAddToShoppingCard,
    StyledCurrency,
    StyledSection,
    StyledTypography,
    StyledTypographyPrice,
    WrapperSectionContent
} from "@features/shared/ProductBlock/styledComponents";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import {calculateCountsPrice, } from "@helper/utils";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch } from 'react-redux';
import { addItem } from '@services/reducers/basketSlice';
import { StyledTypographyFilter } from "@features/shared/styledComponents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import {TextFieldInputCount} from "@features/shared/ProductBlock/shared";

export const ProductBlockAccessories = ({ product }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedWidth, setSelectedWidth] = useState(product.price[0].width);
    const [selectedLength, setSelectedLength] = useState(300);
    const [lengthError, setLengthError] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(product.price[0].price);
    const [additionalPriceChecked, setAdditionalPriceChecked] = useState([]);
    const [buttonText, setButtonText] = useState("Додати в кошик");
    const [selectedCounts, setSelectedCounts] = useState(1);


    const handleCountChange = (event, index) => {
        const value = event.target.value;

        setSelectedCounts(value);
    };



    const from = state?.from || '/mosquitoNets';

    const handleBackToList = () => navigate(from);



    const handleChangeAdditionalPrice = (event) => {
        const additionalPriceObject = product.additionalPrice.find(item => item._id === event.target.name);

        if (event.target.checked) {
            setAdditionalPriceChecked([...additionalPriceChecked, additionalPriceObject]);
        } else {
            setAdditionalPriceChecked(additionalPriceChecked.filter(item => item._id !== event.target.name));
        }
    };

    const getSelectedLengthError = lengthError => lengthError < 300 ?
        "мінімальна довжина 300 міліметрів" :
        "максимальна довжина 6000 міліметрів";

    const handleButtonClick = () => {
        const uniqueId = `${product._id}_${new Date().getTime()}`;

        const item = {
            uniqueId,
            id: product._id,
            img: product.image.url,
            name: product.name,
            quantity: selectedCounts,
            selectedLength,
            selectedWidth,
            additionalPriceChecked,
            finalPrice: calculateCountsPrice(currentPrice, selectedCounts),
        }



        if (buttonText === 'Оформити замовлення') {
            navigate('/basket');
        } else {
            setIsLoading(true);
            setTimeout(() => {
                dispatch(addItem(item));
                setIsLoading(false);
                setButtonText("Оформити замовлення");
            }, 1000);
        }
    };

    const allVariantsAreNone = (config) => config.every(item => item.variant === "None");
    const isMainState = buttonText === 'Додати в кошик';

    return (
        <ProductBlockContainer container spacing={2}>
            <Grid item xl={4} lg={5} md={12} sm={12}>
                <img
                    src={`${product?.image?.url}`}
                    alt={product.title}
                    loading="lazy"
                    width="100%"
                />
            </Grid>
            <Grid item xl={8} lg={7} md={12} sm={12}>
                <StyledTypography as="h2">{product.name}</StyledTypography>
                {!allVariantsAreNone(Object.values(product.config)) && <>
                    <StyledSection item xs={12}>Характеристики</StyledSection>
                    <WrapperSectionContent>
                        {Object.values(product.config).map((item) => {
                            if (item.variant === "None") return null;
                            return (
                                <Grid container key={item}>
                                    <StyledTypographyFilter bold={true}>{item.type}:</StyledTypographyFilter>
                                    <StyledTypographyFilter>{item.variant}</StyledTypographyFilter>
                                </Grid>
                            );
                        })}
                    </WrapperSectionContent>
                </>}
                <StyledSection item xs={12} mt={1} mb={1}>Кількість товарів</StyledSection>

                <StyledTypographyFilter mb={1}>
                    Введіть кількість товару. Система автоматично підрахує вартість згідно
                    вказаної кількості.
                </StyledTypographyFilter>

                <TextFieldInputCount
                    selectedCount={selectedCounts}
                    handleCountChange={(event) => handleCountChange(event)}
                    lengthError={lengthError}
                    getSelectedLengthError={getSelectedLengthError}
                    title=""
                />

                <FormGroup>
                    {product?.additionalPrice?.map((item, index) => (
                        <Grid container alignItems="center" key={item._id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={additionalPriceChecked.some(checkedItem => checkedItem._id === item._id)}
                                        onChange={handleChangeAdditionalPrice}
                                        name={item._id}
                                    />
                                }
                                label={
                                    <Typography sx={{ color: theme.palette.primary.main }}>
                                        {item.additionally}
                                    </Typography>
                                }
                            />
                            <TextFieldInputCount
                                selectedCount={selectedCounts[index]}
                                handleCountChange={(event) => handleCountChange(event, index)}
                                lengthError={lengthError}
                                getSelectedLengthError={getSelectedLengthError}
                                title=""
                            />
                        </Grid>
                    ))}
                </FormGroup>

                <StyledSection item xs={12} mt={1}>Ціна</StyledSection>
                <Grid container mt={1}>
                    {!isMainState &&
                        <Button startIcon={<ArrowBackIcon />} onClick={handleBackToList}>Продовжити покупки</Button>
                    }
                    {isMainState &&
                        <>
                            <StyledTypographyPrice>
                                {Boolean(lengthError)
                                    ? "0"
                                    : calculateCountsPrice(currentPrice, selectedCounts)}
                            </StyledTypographyPrice>
                            <StyledCurrency>грн</StyledCurrency>
                        </>
                    }
                    <StyledButtonAddToShoppingCard
                        loading={isLoading}
                        onClick={handleButtonClick}
                        disabled={Boolean(lengthError)}
                        variant="contained"
                        isMainState={isMainState} // передаємо isMainState як пропс
                    >
                        <ShoppingCartIcon sx={{ marginRight: 1 }} /> {buttonText}
                    </StyledButtonAddToShoppingCard>
                </Grid>
            </Grid>
        </ProductBlockContainer>
    );
};