import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import React from "react";
import {styled} from "@mui/material/styles";


const StyledTableHead = styled(TableHead)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 20px;
`;

const StyledTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  margin-bottom: 20px;
`;

export const TableHeader = () => {
    return <StyledTableHead>
        <TableRow>
            <StyledTableCell>Дія</StyledTableCell>
            <StyledTableCell>Фото</StyledTableCell>
            <StyledTableCell>Імʼя / опис</StyledTableCell>
            <StyledTableCell>Ціна</StyledTableCell>
            <StyledTableCell>Id</StyledTableCell>
            <StyledTableCell>Edit/Remove</StyledTableCell>
        </TableRow>
    </StyledTableHead>
}