import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { AddSalamanderProductModal} from "../../modals";
import {SalamanderCollapsibleTable} from "@features/Admin/content/otherPages/Salamander/salamanderCollapsibleTable";

const StyledTypography = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main};
    margin-bottom: 20px;
`;

export const SalamanderContent = ({ products = [], category }) => {
    const [openAddProduct, setOpenAddProduct] = React.useState(false);
    const params = useParams();

    const handleOpenAddProduct = () => setOpenAddProduct(true);
    const handleCloseAddProduct = () => setOpenAddProduct(false);

    const title = `${params.page}`;


    return (
        <Grid component="main" sx={{ flexGrow: 1, p: 3, mt: 2 }}>
            <Grid sx={{ display: 'flex' }}>
                <StyledTypography as="h2">{title} 🦎</StyledTypography>
                <Grid sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <Button onClick={handleOpenAddProduct} variant="contained" color="secondary">
                        Додати товар <CreateNewFolderIcon sx={{ marginLeft: 1 }} />
                    </Button>
                </Grid>
            </Grid>

            {products.length
                ? <SalamanderCollapsibleTable products={products} category={category} />
                : <div>Немає товарів для відображення</div>}

            <AddSalamanderProductModal
                position={products.length > 0 ? products[products.length - 1].position + 1 : 1}
                open={openAddProduct}
                handleClose={handleCloseAddProduct}
            />
        </Grid>
    );
};