// src/queries/salamander.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
});

export const reorderSalamanderProducts = createAsyncThunk(
    'salamander/reorder',
    async (productOrder, { dispatch, rejectWithValue }) => {
        try {
            const response = await api.put('/instock/products/reorder', { productOrder });
            dispatch(fetchSalamanderProducts());
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const createSalamanderProduct = createAsyncThunk(
    'salamander/createProduct',
    async (product, { rejectWithValue }) => {
        try {
            const response = await api.post('/instock/products', product);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateSalamanderProduct = createAsyncThunk(
    'salamander/updateProduct',
    async (updateProduct, { rejectWithValue }) => {
        const { id, formData } = updateProduct;
        if (!id || !formData) {
            return rejectWithValue('Invalid product object: missing id or formData');
        }
        try {
            const response = await api.put(`/instock/products/${id}`, formData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchSalamanderProducts = createAsyncThunk(
    'salamander/fetchProducts',
    async () => {
        const response = await api.get('/instock/products');
        const products = response.data.sort((a, b) => a.position - b.position);
        return products;
    }
);

export const fetchSalamanderProduct = createAsyncThunk(
    'salamander/fetchProduct',
    async (id) => {
        const response = await api.get(`/instock/products/${id}`);
        return response.data;
    }
);

export const deleteSalamanderProduct = createAsyncThunk(
    'salamander/deleteProduct',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/instock/products/${productId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);