// 🦎 Row адаптований для Salamander
import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { ImageListItem, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import { EditSalamanderProductModal } from "../../../../modals";
import { deleteSalamanderProduct } from "@services/queries/salamander-lviv";

const StyledIconButton = styled(IconButton)`
    color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Row = ({ provided, style, product }) => {
    const status = useSelector((state) => state["salamander-lviv"]?.status);
    const theme = useTheme();
    const dispatch = useDispatch();
    const [openEditProduct, setOpenEditProduct] = React.useState(false);

    const handleOpenEditProduct = () => setOpenEditProduct(true);
    const handleCloseEditProduct = () => setOpenEditProduct(false);

    const handleDelete = (productID) => {
        if (window.confirm('Ви впевнені, що хочете видалити цей продукт?')) {
            dispatch(deleteSalamanderProduct(productID));
        }
    };

    if (status === 'loading') {
        return (
            <TableRow>
                <TableCell colSpan={6}>
                    <Skeleton animation="wave" variant="rectangular" height={100} />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <React.Fragment key={product._id}>
            <TableRow
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={style}
            >
                <TableCell width='10%'>
                    <StyledIconButton
                        theme={theme}
                        {...provided.dragHandleProps}
                    >
                        <DragIndicatorIcon style={{ color: theme.palette.secondary.main }} />
                    </StyledIconButton>
                </TableCell>
                <TableCell width='10%'>
                    <ImageListItem>
                        <img
                            src={`${product?.image?.url}?timestamp=${Date.now()}`}
                            alt={product.name}
                            loading="lazy"
                        />
                    </ImageListItem>
                </TableCell>
                <TableCell width='30%'>
                    <Typography variant="subtitle1">
                        {product.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {product.description}
                    </Typography>
                </TableCell>
                <TableCell width='20%'>
                    <Typography variant="subtitle2">
                        Ціна: {product.price} грн
                    </Typography>
                </TableCell>
                <TableCell width='10%'>
                    <Typography variant="caption">
                        {product._id}
                    </Typography>
                </TableCell>
                <TableCell width='10%'>
                    <StyledIconButton
                        theme={theme}
                        aria-label="edit"
                        size="small"
                        onClick={handleOpenEditProduct}
                    >
                        <EditIcon style={{ color: theme.palette.success.main }} />
                    </StyledIconButton>
                    <StyledIconButton
                        theme={theme}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDelete(product._id)}
                    >
                        <DeleteForeverIcon style={{ color: theme.palette.error.main }} />
                    </StyledIconButton>
                </TableCell>
            </TableRow>

            <EditSalamanderProductModal
                product={product}
                open={openEditProduct}
                handleClose={handleCloseEditProduct}
            />
        </React.Fragment>
    );
};