import Grid from "@mui/material/Unstable_Grid2";
import logo from "@assets/img/logo.png";
import BasketBtn from "@features/shared/BasketBtn";
import React from "react";
import {
    Logo,
    StyledDivider,
    StyledHeader, WrapperHeaderContainer,
    WrapperHeaderContent,
    WrapperHeaderItem, WrapperHeaderLogoContainer,
} from "./styledComponents";
import {StyledTypographyFilter} from "@features/shared/styledComponents";
import {Link} from "@mui/material";

export const Header = ({ theme, url }) => <StyledHeader>
    <WrapperHeaderContainer container >
        <WrapperHeaderLogoContainer xs={12} sm={4} lg={2} onClick={url}>
            <Logo src={logo} alt="Logo" />
        </WrapperHeaderLogoContainer>
        <WrapperHeaderContent  xs={12} lg={10}>
            <WrapperHeaderItem >
                <StyledTypographyFilter bold>адреса:</StyledTypographyFilter>
                <StyledTypographyFilter>Львів, Наукова 43 б</StyledTypographyFilter>
            </WrapperHeaderItem>
            <StyledDivider orientation="vertical" flexItem />
            <WrapperHeaderItem >
                <StyledTypographyFilter bold>телефон:</StyledTypographyFilter>
                <StyledTypographyFilter>
                    <Link href="tel:+380678602100">+380678602100</Link>
                </StyledTypographyFilter>
            </WrapperHeaderItem>
            <StyledDivider orientation="vertical" flexItem />
            <WrapperHeaderItem >
                <StyledTypographyFilter bold>графік роботи:</StyledTypographyFilter>
                <StyledTypographyFilter>Пн-Пт: 9:00-18:00</StyledTypographyFilter>
            </WrapperHeaderItem>
            <StyledDivider orientation="vertical" flexItem />
            <BasketBtn theme={theme} />
        </WrapperHeaderContent>

    </WrapperHeaderContainer>

</StyledHeader>

