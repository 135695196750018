// App.js
import React from "react";
import {
    ProductPage,
    Admin,
    Home,
    Basket,
    LoginForm,
    RefundPolicy,
    TermsAndConditions,
    PaymentSuccess,
    PaymentFail
} from '../features';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MainLayout } from "@features/shared/Layouts";
import ProtectedRoute from './routes/ProtectedRoute';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '@services/reducers/user';

function App() {
    const dispatch = useDispatch();

    // Відновлення стану користувача з localStorage при ініціалізації додатка
    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            dispatch(loginSuccess(user));
        }
    }, [dispatch]);

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/login" element={<MainLayout><LoginForm /></MainLayout>} />
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute element={Admin} />
                        }
                    />
                    <Route
                        path="/admin/:page"
                        element={
                            <ProtectedRoute element={Admin} />
                        }
                    />
                    <Route path="/" element={<MainLayout><Home /></MainLayout>} />
                    <Route path="/refund-policy" element={<MainLayout><RefundPolicy /></MainLayout>} />
                    <Route path="/terms-and-conditions" element={<MainLayout><TermsAndConditions /></MainLayout>} />

                    <Route path="/windowsill" element={<MainLayout><Home /></MainLayout>} />
                    <Route path="/windowsill/:id" element={<MainLayout><ProductPage category="windowsill" /></MainLayout>} />
                    <Route path="/drainages" element={<MainLayout><Home /></MainLayout>} />
                    <Route path="/drainages/:id" element={<MainLayout><ProductPage category="drainages" /></MainLayout>} />
                    <Route path="/mosquitoNets" element={<MainLayout><Home /></MainLayout>} />
                    <Route path="/mosquitoNets/:id" element={<MainLayout><ProductPage category="mosquitoNets" /></MainLayout>} />
                    <Route path="/accessories" element={<MainLayout><Home /></MainLayout>} />
                    <Route path="/accessories/:id" element={<MainLayout><ProductPage category="accessories" /></MainLayout>} />
                    <Route path="/basket" element={<MainLayout><Basket /></MainLayout>} />
                    <Route path="/payment-success" element={<MainLayout><PaymentSuccess /></MainLayout>} />
                    <Route path="/payment-fail" element={<MainLayout><PaymentFail /></MainLayout>} />
                    <Route path="*" element={<h1>404</h1>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
