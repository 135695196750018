import React from 'react';
import {Typography, Container, Box, Link, List, ListItem, Button} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

export const RefundPolicy = () => {
    const navigate = useNavigate();
    const backToHome = () => navigate('/')
    return (
        <Container maxWidth="md">
            <Button startIcon={<ArrowBackIcon/>} onClick={backToHome}>Повернутись на головну</Button>
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Політика повернення коштів
                </Typography>
                <Typography variant="body1" paragraph>
                    Ми цінуємо кожного нашого клієнта та прагнемо забезпечити найвищу якість наших товарів і послуг.
                    Будь ласка, ознайомтеся з нашою політикою щодо повернення коштів та заміни товарів.
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    1. Індивідуальне виготовлення товарів
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Індивідуальні замовлення:</strong> Усі наші товари виготовляються за вашими індивідуальними
                    розмірами та побажаннями.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Відсутність повернення коштів:</strong> Через індивідуальний характер замовлень, ми
                    не здійснюємо повернення коштів або товарів належної якості. Усі замовлення виготовляються
                    згідно з вашими вимогами, і повернення або обмін таких товарів не передбачені.
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    2. Заміна бракованого товару
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Виявлення браку:</strong> Якщо ви отримали товар з виробничим браком або пошкодженнями,
                    просимо повідомити нас протягом 14 календарних днів з моменту отримання замовлення.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Процедура заміни:</strong> Ми не повертаємо кошти за браковані товари, але здійснюємо безкоштовну заміну або перероблення товару.
                </Typography>
                <List>
                    <ListItem>
                        <Typography variant="body1">
                            Зв'яжіться з нами за телефоном{' '}
                            <Link href="tel:+380678602100">+380678602100</Link>
                            {' або електронною поштою '}
                            <Link href="mailto:nobilux.vikna@ukr.net">nobilux.vikna@ukr.net</Link>.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        Надайте детальний опис проблеми та, за можливості, фото або відео підтвердження браку.
                    </ListItem>
                </List>
                <Typography variant="body1" paragraph>
                    <strong>Умови заміни:</strong> Після підтвердження браку ми безкоштовно замінимо товар на аналогічний
                    або, за необхідності, виконаємо його перероблення.
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    3. Важливі зауваження
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Правильна експлуатація:</strong> Товар не підлягає заміні або поверненню в разі пошкоджень,
                    спричинених неправильною експлуатацією або доглядом.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Законодавча основа:</strong> Наша політика відповідає Закону України "Про захист прав
                    споживачів" та іншим чинним нормативно-правовим актам.
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    4. Контактна інформація
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Телефон:</strong>{' '}
                    <Link href="tel:+380678602100">+380678602100</Link>
                    <br/>
                    <strong>Email:</strong>{' '}
                    <Link href="mailto:nobilux.vikna@ukr.net">nobilux.vikna@ukr.net</Link>
                    <br/>
                    <strong>Адреса:</strong> м. Львів, вул. Наукова 43 б
                </Typography>
            </Box>
        </Container>
    )
};