// payment-fail.jsx
import React from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const PaymentFail = () => (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Avatar sx={{ bgcolor: 'error.main', width: 70, height: 70, mx: 'auto', mb: 2 }}>
            <ErrorOutlineIcon sx={{ color: '#fff', fontSize: 40 }} />
        </Avatar>
        <Typography variant="h4" color="error.main">
            Оплата не пройшла!
        </Typography>
        <Typography variant="body1" sx={{ my: 2 }}>
            На жаль, сталася помилка або платіж було відхилено. Спробуйте ще раз або зв'яжіться з нами.
        </Typography>
        <Button variant="contained" href="/basket">
            Спробувати ще раз
        </Button>
    </Box>
);

